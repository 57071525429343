import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const MainDiv = styled.div``;

export const RowStyle = styled(Row)``;

export const ColStyle = styled(Col)``;

export const HeaderH1 = styled.h1`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  font-size: 45px;
  line-height: 50px;
  color: #083a81;
  @media (max-width: 991px) {
    padding-top: 10px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH2 = styled.h2`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  font-size: 35px;
  line-height: 40px;
  color: #0069aa;
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  code {
    font-size: 12px;
    line-height: 18px;
    color: #666;
    font-family: 'Gotham-Book', arial, sans-serif;
    letter-spacing: 0.02em;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1 !important;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;

export const LinkStyle = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #1fb25a;
  :hover {
    text-decoration: none;
    color: #0397d6;
    outline: none;
  }
`;

export const ImageStyle = styled.img`
  height: auto !important;
  width: auto !important;
  border: 0;
  vertical-align: top;
  margin: 0 0 30px 0;
  padding: 0;
  @media (max-width: 399px) {
    width: 100% !important;
    height: auto !important;
  }
`;
